<ng-container *ngIf="vm$ | async as vm">
  <mat-card
    *ngFor="let comment of vm.commentList"
    class="my-3 mat-elevation-z2"
  >
    <mat-card-header class="justify-content-between">
      <mat-card-title>
        <div>
          {{ comment?.commentedBy }}
        </div>
      </mat-card-title>
      <div *ngIf="vm.appUser?.isAdmin">
        <button
          mat-icon-button
          matTooltip="Delete comment"
          matTooltipPosition="before"
          color="accent"
          (click)="deleteComment(comment?.commentId)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <mat-card-subtitle>{{
        comment?.commentDate | date : "medium"
      }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="mt-3">
      <p>{{ comment?.content }}</p>
    </mat-card-content>
  </mat-card>
</ng-container>
