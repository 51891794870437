<p _ngcontent-psg-c141="" class="alert alert-success">
  <!-- <li _ngcontent-psg-c141="">
    This is a sample blogging app using Angular and Google cloud firestore.
  </li>
  <li _ngcontent-psg-c141="">
    Source code is available on
    <a
      _ngcontent-psg-c141=""
      href="https://github.com/AnkitSharma-007/blogging-app-with-Angular-CloudFirestore"
      target="_blank"
      >GitHub.</a
    >
  </li>
  <li _ngcontent-psg-c141="">
    Authorization is disabled here intentionally, to allow all the users to
    experience the full feature.
  </li>
  <li _ngcontent-psg-c141="">
    To understand how role-based authorization is implemented, get the source
    code from GitHub.
  </li>
  <li _ngcontent-psg-c141="">
    Login with Google to add/edit/delete a sample blog.
  </li>
  <li _ngcontent-psg-c141="">
    Follow common courtesy. Do no delete any exisiting blog.
  </li>
  <li _ngcontent-psg-c141="">
    For any feedback/suggestion contact me via
    <a
      _ngcontent-psg-c141=""
      href="https://twitter.com/ankitsharma_007"
      target="_blank"
      >Twitter.</a
    >
  </li> -->
</p>
