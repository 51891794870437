// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "demo",
//     authDomain: "blogsite-30c69.firebaseapp.com",
//     databaseURL: "demo",
//     projectId: "blogsite-30c69",
//     storageBucket: "demo",
//     messagingSenderId: "demo",
//     appId: "demo",
//   },
// };


export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCiOzQF4J5GbqWz8WxfmxYscIuj6ench_8",
    authDomain: "sanblog-6ebef.firebaseapp.com",
    databaseURL: "sanblog-6ebef.appspot.com",
    projectId: "sanblog-6ebef",
    storageBucket: "sanblog-6ebef.appspot.com",
    messagingSenderId: "13415233354",
    appId: "1:13415233354:web:442c7b1f9b6f72814673b3",
    measurementId: "G-XNH6ZBN1T7",
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
