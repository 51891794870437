<ng-container *ngIf="blog$ | async as blog; else loader">
  <mat-card class="mt-3 p-1 mat-elevation-z2 bg-white">
    <mat-card-header>
      <mat-card-title>
        <h1 class="my-2 display-4">{{ blog?.title }}</h1>
      </mat-card-title>
      <mat-card-subtitle class="my-4">
        <fa-icon [icon]="faCalendar"></fa-icon>
        {{ blog?.createdDate | date : "longDate" }}
        <fa-icon class="ms-2" [icon]="faUser"></fa-icon>
        {{ blog?.author | titlecase }}
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-divider></mat-divider>
    </mat-card-content>

    <mat-card-content class="py-3 align-items-center align-content-center">
      <div [innerHTML]="blog?.content"></div>
    </mat-card-content>
  </mat-card>
</ng-container>
<ng-template #loader>
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
<!-- <app-social-share></app-social-share> -->
<mat-divider></mat-divider>
<app-post-comment [blogId]="postId"></app-post-comment>
<app-comments [blogId]="postId"></app-comments>
<app-scroller></app-scroller>
