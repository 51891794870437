<div class="my-3 p-1">
  <p>
    <strong>Found this article helpful!!! Share this with your Friends</strong>
  </p>

  <button
    mat-fab
    shareButton="facebook"
    [style.backgroundColor]="share.prop.facebook.color"
  >
    <fa-icon [icon]="share.prop.facebook.icon" size="lg"></fa-icon>
  </button>
  <button
    mat-fab
    shareButton="twitter"
    [style.backgroundColor]="share.prop.twitter.color"
  >
    <fa-icon [icon]="share.prop.twitter.icon" size="lg"></fa-icon>
  </button>
  <button
    mat-fab
    shareButton="linkedin"
    [style.backgroundColor]="share.prop.linkedin.color"
  >
    <fa-icon [icon]="share.prop.linkedin.icon" size="lg"></fa-icon>
  </button>
  <button
    mat-fab
    shareButton="reddit"
    [style.backgroundColor]="share.prop.reddit.color"
  >
    <fa-icon [icon]="share.prop.reddit.icon" size="lg"></fa-icon>
  </button>
  <button
    mat-fab
    shareButton="whatsapp"
    [style.backgroundColor]="share.prop.whatsapp.color"
  >
    <fa-icon [icon]="share.prop.whatsapp.icon" size="lg"></fa-icon>
  </button>
  <button
    mat-fab
    shareButton="telegram"
    [style.backgroundColor]="share.prop.telegram.color"
  >
    <fa-icon [icon]="share.prop.telegram.icon" size="lg"></fa-icon>
  </button>
  <button
    mat-fab
    shareButton="print"
    [style.backgroundColor]="share.prop.print.color"
  >
    <fa-icon [icon]="share.prop.print.icon" size="lg"></fa-icon>
  </button>
  <button
    mat-fab
    shareButton="email"
    [style.backgroundColor]="share.prop.email.color"
  >
    <fa-icon [icon]="share.prop.email.icon" size="lg"></fa-icon>
  </button>
</div>
