<mat-card class="mb-3 mat-elevation-z2">
  <mat-card-content>
    <div class="text-center">
      <img
        class="text-center author-image m-1"
        src="../../../assets/SKG.png"
      />
      <h5>Sanjay Gope</h5>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-content class="d-flex justify-content-center py-2">
    <!-- <a href="https://www.facebook.com/Ankit.Sharma.0709" target="_blank"
      ><fa-icon class="facebook-icon mx-1" [icon]="facebookIcon"></fa-icon>
    </a>
    <a href="https://twitter.com/ankitsharma_007" target="_blank"
      ><fa-icon class="twitter-icon mx-1" [icon]="twitterIcon"></fa-icon
    ></a>
    <a href="https://www.linkedin.com/in/ankitsharma-007/" target="_blank"
      ><fa-icon class="linkedin-icon mx-1" [icon]="linkedinIcon"></fa-icon
    ></a>
    <a href="https://github.com/AnkitSharma-007" target="_blank"
      ><fa-icon class="github-icon mx-1" [icon]="githubIcon"></fa-icon
    ></a> -->
  </mat-card-content>
</mat-card>
