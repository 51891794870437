<h1 class="mt-2 display-4">{{ formTitle }} Post</h1>
<hr />
<mat-card class="mat-elevation-z2 my-3">
  <mat-card-content>
    <form
      #myForm="ngForm"
      (ngSubmit)="myForm.form.valid && saveBlogPost()"
      accept-charset="UTF-8"
      novalidate
    >
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Title</mat-label>
        <input
          matInput
          placeholder="Add title..."
          name="postTitle"
          [(ngModel)]="postData.title"
          #postTitle="ngModel"
          required
        />
        <mat-error *ngIf="myForm.submitted && postTitle.errors?.required"
          >Title is required</mat-error
        >
      </mat-form-field>

      <div class="form-group">
        <ckeditor
          name="myckeditor"
          [config]="ckeConfig"
          [(ngModel)]="postData.content"
          #myckeditor="ngModel"
          debounce="300"
          [editor]="Editor"
        ></ckeditor>
      </div>
      <div class="py-3" align="end">
        <button class="me-2" type="submit" mat-raised-button color="primary">
          Save
        </button>
        <button mat-raised-button color="warn" (click)="navigateToHome()">
          Cancel
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
