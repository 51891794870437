<ng-template #loading>
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-container *ngIf="vm$ | async as vm; else loading">
  <ng-container *ngIf="vm.blogList?.length > 0; else noData">
    <div *ngFor="let post of vm.blogList | paginate : config">
      <mat-card class="mat-elevation-z2 mb-3">
        <mat-card-header>
          <a
            class="blog-title"
            [routerLink]="['/blog/', post.postId, post.title | slug]"
          >
            <h2>{{ post.title }}</h2>
          </a>
        </mat-card-header>
        <mat-card-content class="my-2">
          <div  [innerHTML]="post.content | excerpt"></div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions align="end" class="my-2">
          <ng-container *ngIf="vm.appUser?.isAdmin">
            <button
              class="mx-2"
              mat-raised-button
              color="accent"
              [routerLink]="['/editpost', post.postId]"
            >
              Edit
            </button>
            <button
              mat-raised-button
              color="warn"
              (click)="delete(post.postId)"
            >
              Delete
            </button>
          </ng-container>
          <span class="spacer"></span>
          <button
            mat-raised-button
            color="primary"
            [routerLink]="['/blog/', post.postId, post.title | slug]"
          >
            Read More
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <app-paginator
      [pageSizeOptions]="pageSizeOptions"
      [config]="config"
    ></app-paginator>
  </ng-container>
  <ng-template #noData>
    <h3>No data found</h3>
  </ng-template>
</ng-container>
